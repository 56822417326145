import React, {useContext, createContext, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {useLazyQuery, gql} from "@apollo/client";
import GetOneUser from "./gql/GetOneUser.graphql";
import {ALERT} from "../environment";
import {useParams, useNavigate} from "react-router-dom";
import {useUsers} from "./UsersContext";
import {useLogin} from "../components/login/LoginProvider";
import axios from "axios";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;
export const UserContext = createContext(null);

export const UserProvider = ({children}) => {
  const {user: loggedInUser} = useLogin();
  const [user, setUser] = useState();
  const {sub} = useParams();
  const {users} = useUsers();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const navigate = useNavigate();

  const [getUser, getUserQuery] = useLazyQuery(gql(GetOneUser), {
    onCompleted: (data) => {
      setUser(data.user);
    },
  });

  useEffect(() => {
    if (!users) return;

    const foundUser = users.find(
        (user) => user.Attributes.find((attr) => attr.Name === "sub").Value === sub,
    );

    if (!foundUser) {
      navigate("/users");
    }

    getUser({variables: {username: foundUser.Username}});
  }, [users]);

  const update = async (data) => {
    const groupNames = data.Groups.map((group) => group.name);

    const input = {
      username: user.Username,
      groups: groupNames,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${loggedInUser.IdToken}`,
      },
    };
    setIsUpdateLoading(true);
    const response = await axios.post(`${AUTH_ENDPOINT}/manage/groups/update`, input, config);
    setIsUpdateLoading(false);
    if (response.status === 200) {
      ALERT.success("User successfully updated.");
    }
    getUser({variables: {username: user.Username}});
  };

  const updateUserPhoneAndMFA = async (data) => {
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/manage/user/phone`, data, {
        headers: {
          Authorization: `Bearer ${loggedInUser.IdToken}`,
        },
      });
      if (response.status === 200) ALERT.success("Phone and MFA updated.");
    } catch (error) {
      ALERT.error(error?.response?.data?.message ?? "Phone and MFA were not updated.");
    }
  };

  return (
    <UserContext.Provider
      value={{
        getUserQuery,
        user,
        update,
        updateUserPhoneAndMFA,
        refetch: getUserQuery.refetch,
        isUpdateLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.any,
};

export const useUser = () => {
  return useContext(UserContext);
};
