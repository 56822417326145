import React, {useEffect} from "react";
import {Typography, Box, Button, IconButton, TextField} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useUser} from "./UserContext";
import {NavLink} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import {UserProvider} from "./UserContext";
import UserDetailsUpdateFields from "./UserDetailsUpdateFields";
import UserGroupsSelect from "./UserGroupsSelect";
import dayjs from "dayjs";
import shajs from "sha.js";
import {isArray, map} from "underscore";

const UserDetailsContent = () => {
  const {user, refetch, update, isUpdateLoading} = useUser();
  const formMethods = useForm();

  const getUserDisplayName = (user) => {
    const name = user.UserAttributes.find((attr) => attr.Name === "custom:customerName");
    if (name) return name.Value;
    return user.Username;
  };

  useEffect(() => {
    if (!user) return;
    formMethods.reset(user);
  }, [user]);

  if (!user) {
    return (
      <center>
        <Typography variant="h3">Loading</Typography>
      </center>
    );
  }

  const MFAList = isArray(user?.UserMFASettingList) ? user.UserMFASettingList.join(", ") : "None";
  const userPreferredMfaMethod = user?.PreferredMfaSetting;

  const userDetails = (
    <Typography variant="caption">
      <b>enabled</b>: {`${user?.Enabled}`}
      <br />
      <b>status</b>: {`${user?.UserStatus}`}
      <br />
      {map(user?.UserAttributes, (attribute, index) => (
        <span key={index}>
          <b>{attribute?.Name}</b>: {attribute?.Value}
          <br />
        </span>
      ))}
      <b>SHA-256</b>: {shajs("sha256").update(user?.Username).digest("hex")}
      <br />
      <b>userMFASettingList</b>: {MFAList}
      <br />
      <b>Preferred MFA Method</b>: {userPreferredMfaMethod}
      <br />
      <b>created</b>: {`${dayjs(user?.UserCreateDate).format("LLL")}`}
      <br />
      <b>last modified</b>: {`${dayjs(user?.UserLastModifiedDate).format("LLL")}`}
      <br />
    </Typography>
  );

  return (
    <Box sx={{justifyContent: "center", alignItems: "center"}}>
      <Box alignContent="center" justifyContent="center">
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box padding={1} display="flex">
            <IconButton
              color="primary"
              aria-label="go-back"
              component={NavLink}
              to="/users"
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">User: {getUserDisplayName(user)}</Typography>
          </Box>
          <Box display="flex" justifyContent="end" marginY={1.5}>
            <Button color="primary" variant="contained" onClick={() => refetch()}>
              Refresh
            </Button>
          </Box>
        </Box>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(update)}>
            <Box padding={1}>
              <Typography variant="h5" sx={{marginBottom: 3}}>
                Overview
              </Typography>
              <TextField
                {...formMethods.register("Username", {required: true})}
                variant="outlined"
                id="username"
                label="Email"
                size="small"
                fullWidth={true}
                InputLabelProps={{shrink: true}}
                disabled
              />
            </Box>
            <Box padding={1}>
              <UserGroupsSelect />
            </Box>
            <Box padding={1}>
              <Button type="submit" variant="outlined" disabled={isUpdateLoading}>
                Update
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
      <UserDetailsUpdateFields />
      <Typography variant="h5" sx={{paddingLeft: 1, marginTop: 3}}>
        Details
      </Typography>
      <Box p={2}>{userDetails}</Box>
    </Box>
  );
};

const UserDetails = () => {
  return (
    <UserProvider>
      <UserDetailsContent />
    </UserProvider>
  );
};

export default UserDetails;
