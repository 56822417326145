import React, {createContext, useContext, useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as yup from "yup";
import {Login} from "./Login";
import MFAChallengeForm from "./MFAChallengeForm";
import {clearCookieSession, getCookieSession, setCookieSession} from "../../shared/cookies";
import jwtDecode from "jwt-decode";
import {contains, intersection, isEmpty} from "underscore";

export const LoginContext = createContext({
  login: () => {},
  loginSchema: {},
  user: {},
  setUser: () => {},
  refreshSession: () => {},
  logout: () => {},
});
const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const LoginProvider = ({children}) => {
  const [user, setUser] = useState(getCookieSession() ?? {});
  const [mfaChallenge, setMFAChallenge] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loginSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required(),
  }).required();

  const isValidAdminUser = (idToken) => {
    return !isEmpty(intersection(idToken["cognito:groups"], ["Admin", "RegulationAdmin"]));
  };

  const isUserFullAdmin = () => {
    if (!user || !user.IdToken) return false;
    const decodedToken = jwtDecode(user.IdToken);
    return (decodedToken["cognito:groups"]?.includes("Admin"));
  };

  const login = async (data, setError) => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`${AUTH_ENDPOINT}/login`, {}, {auth: data});
      if (response.status === 200) {
        if (contains(["SMS_MFA", "SOFTWARE_TOKEN_MFA"], response.data?.ChallengeName)) {
          setIsSubmitting(false);
          setMFAChallenge({userData: data, authSession: response.data});
          return;
        }
        const idToken = jwtDecode(response.data.AuthenticationResult.IdToken);
        if (!isValidAdminUser(idToken)) throw new Error("You are not an admin.");
        setUser(response.data.AuthenticationResult);
        if (data.isMemorable) {
          setCookieSession({username: data.username, ...response.data.AuthenticationResult});
        };
      }
    } catch (error) {
      console.log(error);
      setError("password", {
        type: "manual", message: error?.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const refreshSession = async () => {
    try {
      const response = await axios.post(
          `${AUTH_ENDPOINT}/token/refresh`,
          {
            AuthParameters: {
              REFRESH_TOKEN: user.RefreshToken,
              USERNAME: user.username,
            },
          },
      );
      if (response.status === 200) {
        const newUser = {...response.data, username: user.username};
        setUser(newUser);
        setCookieSession(newUser);
        return response.data;
      } else {
        logout();
      };
    } catch (error) {
      console.log(error);
      logout();
    }
  };

  const mfaSchema = yup.object().shape({
    mfaCode: yup.string().matches(/^[0-9]+$/).min(6).max(6),
  }).required();

  const submitMfa = async (data, formMethods) => {
    const username = mfaChallenge.userData.username;
    const challengeName = mfaChallenge.authSession.ChallengeName;
    const challengeReponseCodeKey = `${challengeName}_CODE`;

    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/login/mfa`, {
        ChallengeName: challengeName,
        ChallengeResponses: {
          USERNAME: username,
          [challengeReponseCodeKey]: data.mfaCode,
        },
        Session: mfaChallenge.authSession.Session,
      });
      if (response.status === 200 && !isEmpty(response?.data)) {
        const profile = jwtDecode(response.data.AuthenticationResult.IdToken);
        if (!isValidAdminUser(profile)) throw new Error("You are not an admin.");
        setUser({email: username, ...response.data.AuthenticationResult, profile});
        if (mfaChallenge.userData.isMemorable) {
          setCookieSession({username: username, ...response.data.AuthenticationResult});
        }
        setMFAChallenge({});
      } else {
        formMethods.setError("mfaCode", {type: "manual", message: "Login unsuccessful"});
      }
    } catch (error) {
      formMethods.setError("mfaCode", {
        type: "manual",
        message: error?.response?.data?.message ?? error?.message ?? "Login unsuccessful",
      });
    }
  };

  const logout = async () => {
    clearCookieSession();
    setUser({});
  };

  const providerValues = {
    login,
    loginSchema,
    user,
    setUser,
    isSubmitting,
    isUserFullAdmin,
    refreshSession,
    mfaSchema,
    mfaChallenge,
    setMFAChallenge,
    submitMfa,
    logout,
  };

  return <LoginContext.Provider value={providerValues}>
    {!isEmpty(user) ? children : !isEmpty(mfaChallenge) ? <MFAChallengeForm /> : <Login />}
  </LoginContext.Provider>;
};

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLogin = () => {
  return useContext(LoginContext);
};

export default LoginProvider;
